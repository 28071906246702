<template>
  <v-container fluid>
    <v-card color="white">
      <Info></Info>
    </v-card>
  </v-container>
</template>
<script>
import Info from "@/components/userPanel/info.vue";
export default {
  components: {
    Info
  },
  computed: {
 
  },
  created() {
  },
  methods: {

  }
};
</script>
<style lang="scss">

</style>
